/***
 *
 *   MASTER DASHBOARD
 *   Manage all users signed up to your application
 *
 **********/

import React, { useContext } from 'react';
import Axios from 'axios';
import {
  ViewContext,
  AuthContext,
  Card,
  Table,
  Animate,
  useAPI,
} from 'components/lib';

export function MasterUsers(props) {
  const context = useContext(ViewContext);
  const authContext = useContext(AuthContext);
  const users = useAPI('/api/master/users');

  function editUser(data, callback) {
    context.modal.show(
      {
        title: 'Edit User',
        form: {
          id: {
            type: 'hidden',
            value: data.id,
          },
          account_id: {
            type: 'hidden',
            value: data.default_account,
          },
          name: {
            label: 'Name',
            type: 'text',
            value: data.name,
            requried: true,
          },
          email: {
            label: 'Email',
            type: 'email',
            value: data.email,
            required: true,
          },
        },
        buttonText: 'Save',
        url: '/api/master/user',
        method: 'PATCH',
      },
      (res) => {
        context.notification.show(data.name + ' was updated', 'success', true);
        callback(res);
      },
    );
  }

  function deleteUser(data, callback) {
    context.modal.show(
      {
        title: 'Delete User',
        form: {},
        buttonText: 'Delete User',
        text: `Are you sure you want to delete ${data.email}? This will remove them from all accounts.`,
        url: `/api/master/user/${data.id}`,
        method: 'DELETE',
        destructive: true,
      },
      () => {
        context.notification.show(`${data.email} was deleted`, 'success', true);
        callback();
      },
    );
  }

  async function impersonateUser(data) {
    try {
      const res = await Axios.post('/api/master/user/impersonate', {
        id: data.id,
      });

      if (res.status === 200) authContext.signin(res);
    } catch (err) {
      context.handleError(err);
    }
  }

  return (
    <Animate>
      <Card>
        <Table
          search
          data={users.data}
          loading={users.loading}
          show={['name', 'email', 'date_created', 'last_active', 'onboarded']}
          actions={{
            edit: editUser,
            delete: deleteUser,
            email: true,
            custom: [{ icon: 'log-in', action: impersonateUser }],
          }}
        />
      </Card>
    </Animate>
  );
}

/***
 *
 *   MASTER ACCOUNTS
 *   Manage all accounts signed up to your application
 *
 **********/

import React, { useContext } from 'react';
import {
  ViewContext,
  Card,
  Table,
  Animate,
  usePlans,
  useAPI,
} from 'components/lib';

export function MasterAccounts(props) {
  const context = useContext(ViewContext);
  const plans = usePlans();
  const accounts = useAPI('/api/master/accounts');

  function editAccount(data, callback) {
    context.modal.show(
      {
        title: 'Edit Account',
        form: {
          id: {
            type: 'hidden',
            value: data.id,
          },
          plan: {
            label: 'Plan',
            type: 'select',
            options: plans.data.list,
            default: data.plan,
            required: true,
          },
          active: {
            label: 'Status',
            type: 'select',
            default: data.active,
            options: [
              { value: 1, label: 'Active' },
              { value: 0, label: 'Cancelled' },
            ],
            required: true,
          },
        },
        buttonText: 'Save',
        url: '/api/master/account',
        method: 'PATCH',
      },
      (res) => {
        context.notification.show(data.name + ' was updated', 'success', true);
        callback(res);
      },
    );
  }

  function deleteAccount(data, callback) {
    context.modal.show(
      {
        title: 'Delete Account',
        form: {},
        buttonText: 'Delete Account',
        text: `Are you sure you want to close ${data.email}'s account?`,
        url: `/api/master/account/${data.id}`,
        method: 'DELETE',
        destructive: true,
      },
      () => {
        context.notification.show(`${data.email} was deleted`, 'success', true);
        callback();
      },
    );
  }

  return (
    <Animate>
      <Card loading={false}>
        <Table
          search
          hide={['id']}
          data={accounts?.data}
          loading={accounts?.loading}
          badge={{ col: 'plan', color: 'blue' }}
          actions={{ edit: editAccount, delete: deleteAccount, email: true }}
        />
      </Card>
    </Animate>
  );
}

/***
 *
 *   SIGN IN
 *   Sign in form for all account types (including master).
 *
 **********/

import React, { useContext, useState, useEffect } from 'react';
import {
  Animate,
  AuthContext,
  ViewContext,
  Button,
  Form,
  Card,
  Link,
  Row,
  Message,
  SocialSignin,
  History,
} from 'components/lib';

export function Signin(props) {
  // context
  const authContext = useContext(AuthContext);
  const viewContext = useContext(ViewContext);

  // state
  const [magicLinkSent, setMagicLinkSent] = useState(false);
  const [form, setForm] = useState({
    email: {
      label: 'Email',
      type: 'email',
      required: true,
    },
    password: {
      label: 'Password',
      type: 'password',
      required: true,
    },
    forgotpassword: {
      type: 'link',
      url: '/forgotpassword',
      text: 'Forgot your password?',
    },
  });

  useEffect(() => {
    // was an error message passed from the server router?
    const qs = props.location.search;

    if (qs.includes('error')) {
      const msg = decodeURIComponent(qs.substring(qs.indexOf('?error=') + 7));
      viewContext.notification.show(msg, 'error');
    }
  }, [props.location.search, viewContext.notification]);

  return (
    <Animate type="pop">
      <Row title="Sign in to Indexed">
        {magicLinkSent ? (
          <div style={{ width: '28em', margin: '0 auto' }}>
            <Message
              title="Check Your Email"
              type="success"
              text="Please click the link in your email inbox to sign in."
            />
          </div>
        ) : (
          <Card restrictWidth center>
            <Form
              data={form}
              method="POST"
              url="/api/auth"
              buttonText="Sign In"
              callback={(res) => {
                res.data['2fa_required']
                  ? History.push(`/signin/otp?token=${res.data.token}`)
                  : authContext.signin(res);
              }}
            />

            {/* <span>
              Don't have an account? <Link url="/signup" text="Sign Up" />
            </span> */}
          </Card>
        )}
      </Row>
    </Animate>
  );
}

/***
 *
 *   PROGRESS BAR
 *   Percentage based progress bar with animated fill
 *
 *   PROPS
 *   label: text label (optional)
 *   progress: percentage value: eg. 75%
 *
 **********/

import React from 'react';
import Style from './bar.module.scss';

export function ProgressBar(props) {
  return (
    <section>
      {props.label && <div className={Style.label}>{props.label}</div>}

      <div className={Style.bar}>
        <div className={Style.fill} style={{ width: props.progress }}></div>
      </div>
    </section>
  );
}

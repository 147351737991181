import { useEffect, useState } from 'react';
import { History } from 'components/lib';

export default function useUrlParams(path, encodedUrlParams) {
  const [urlParams, setUrlParams] = useState('');

  const getNewUrlParams = (event) => {
    if (!event.data) return;
    let parsedData;
    try {
      parsedData = JSON.parse(event.data);
    } catch (_) {
      // Not a JSON-onitifed event data, we don't care.
      return;
    }
    const { action = '', result } = parsedData;
    switch (action) {
      case 'onReportParametersChanged':
        const params = JSON.stringify(result);
        const encodedParams = encodeURIComponent(`report_parameters=${params}`);
        const newUrl = `${path}?${encodedParams}`;
        History.push(newUrl);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    window.addEventListener('message', getNewUrlParams);

    return () => {
      window.removeEventListener('message', getNewUrlParams);
    };
  }, []);

  useEffect(() => {
    const decodedParams = decodeURIComponent(
      (encodedUrlParams + '').replace(/\+/g, '%20'),
    ).replace('?', '&');
    setUrlParams(decodedParams);
  }, []);

  return urlParams;
}

import React from 'react';
import { SeekTable } from 'components/seekTable/seekTable';
import { useAPI } from 'components/lib';

export function DevicePriceMatrixOther(props) {
  const crawlPeriod = useAPI('/api/data/getLatestCrawlPeriod');

  return crawlPeriod.loading ? (
    <div>Loading...</div>
  ) : (
    <SeekTable
      location={props.location}
      path={props.path}
      name="Device Price Matrix Other"
      reportId="9a4d2b1ff6c342698d0b91f5432b712d"
      reportParams={{ crawlPeriod: crawlPeriod.data }}
    />
  );
}

import React from 'react';

export function AppSmith(props) {
  const appId = props.appId || '61634765ea18372f0510596f';

  return (
    <div
      style={{
        position: 'absolute',
        top: 100,
        left: 100,
        right: 0,
        bottom: 0,
      }}
    >
      <iframe
        title={props.title}
        border="0"
        frameBorder="0"
        width="100%"
        height="100%"
        src={`https://app.appsmith.com/applications/${appId}/pages/${props.pageId}?embed=true`}
      />
    </div>
  );
}

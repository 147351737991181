/***
 *
 *   ACCOUNT
 *   Index page for account functions
 *
 **********/

import React, { useContext } from 'react';
import {
  AuthContext,
  Animate,
  Grid,
  Card,
  Icon,
  Link,
  useAPI,
  Loader,
} from 'components/lib';

export function Account(props) {
  const context = useContext(AuthContext);
  const user = useAPI('/api/user');

  if (user.loading) return <Loader />;

  return (
    <Animate>
      <Grid cols="4">
        {/* <Card>
          <Icon image="user" />
          <h2>Profile</h2>
          <div>Update your profile information</div>
          <Link url="/account/profile">Update</Link>
        </Card> */}

        <Card>
          <Icon image="lock" />
          <h2>Password</h2>
          <div>
            {user?.data?.['has_password']
              ? 'Change your password'
              : 'Create a password'}
          </div>
          <Link url="/account/password">
            {user?.data?.['has_password'] ? 'Change' : 'Create'}
          </Link>
        </Card>
        {/*
        <Card>
          <Icon image="shield" />
          <h2>Two-Factor Authentication</h2>
          <div>Secure your account</div>
          <Link url="/account/2fa">
            {user?.data?.['2fa_enabled'] ? 'Manage' : 'Enable'}
          </Link>
        </Card>

        {context.permission?.owner && (
          <Card>
            <Icon image="credit-card" />
            <h2>Billing</h2>
            <div>Update your plan or credit card</div>
            <Link url="/account/billing">Manage</Link>
          </Card>
        )}

        {context.permission?.developer && (
          <Card>
            <Icon image="settings" />
            <h2>API Keys</h2>
            <div>Manage your API keys</div>
            <Link url="/account/apikeys">Manage</Link>
          </Card>
        )}

        {context.permission?.admin && (
          <Card>
            <Icon image="users" />
            <h2>Users</h2>
            <div>Invite users to your account</div>
            <Link url="/account/users">Add users</Link>
          </Card>
        )} */}
      </Grid>
    </Animate>
  );
}

import React, { useEffect, useState } from 'react';

import useUrlParams from 'utils/useUrlParams';

export function SeekTable(props) {
  const { location = {}, path = '' } = props;
  const { search = '' } = location;

  // The problem here is that the URL history changes by tweaking the seektable filters, but the actual urlParams
  // don't. So the urlParams stay the same as when the component was instantiated.
  // We want to be able to refresh the ST when you go to the report page and it should be w/o any filters.
  // We force that by using a REV count.

  const [rev, setRev] = useState(0);
  const [url, setUrl] = useState(window.location.href);

  const urlParams = useUrlParams(path, search);

  let reportParams = urlParams;

  if (!reportParams) {
    // check the ones in props.reportParams

    if (props.reportParams) {
      reportParams =
        '&report_parameters=' +
        encodeURIComponent(JSON.stringify(props.reportParams));
    }
  }

  useEffect(() => {
    if (!url.includes('report_parameters')) {
      setRev((r) => r + 1);
    }
  }, [url]);

  //eslint-disable-next-line
  useEffect(() => {
    if (url !== window.location.href) {
      setUrl(window.location.href);
    }
  });

  return (
    <div
      style={{
        position: 'absolute',
        top: 100,
        left: 100,
        right: 20,
        bottom: props.bottomOffset || 20,
      }}
    >
      <iframe
        title={props.name}
        border="0"
        frameBorder="0"
        width="100%"
        height="100%"
        src={`https://vivacom.indexed.company/public/report/${props.reportId}?parameter_change_notify=1&rev=${rev}${reportParams}`}
      />
    </div>
  );
}

import React from 'react';
import { SeekTable } from 'components/seekTable/seekTable';

export function DevicePricingHistory(props) {
  return (
    <SeekTable
      location={props.location}
      path={props.path}
      name="Device Pricing History"
      reportId="0021f10b5c6c486d9d484e85d4be9088"
    />
  );
}

import { DevicePriceMatrixVoice } from 'views/devicePriceMatrix/devicePriceMatrixVoice';
import { CompetitorCheaperDevices } from '../views/competitorCheaperDevices';
import { CompetitorDeviceOverlap } from '../views/competitorDeviceOverlap';
import { DeviceIdentityMapping } from '../views/deviceIdentityMapping/deviceIdentityMapping';
import { EditDeviceIdentityMapping } from '../views/deviceIdentityMapping/editDeviceIdentityMapping';
import { DevicePriceMatrixData } from '../views/devicePriceMatrix/devicePriceMatrixData';
import { DevicePriceMatrixOther } from '../views/devicePriceMatrix/devicePriceMatrixOther';
import { DevicePricingHistory } from '../views/devicePricingHistory';
import { Newsfeed } from '../views/newsfeed';
import { PlansDetails } from '../views/planDetails/planDetails';
import { EditPlanGroups } from '../views/planGroupsAndPrices/editPlanGroups';
import { PlanGroups } from '../views/planGroupsAndPrices/planGroups';
import { EditPlanIdentityMapping } from '../views/planIdentityMapping/editPlanIdentityMapping';
import { PlanIdentityMapping } from '../views/planIdentityMapping/planIdentityMapping';
import { ScheduleCrawl } from '../views/scheduleCrawl';

const Routes = [
  {
    path: '/competitor-device-overlap',
    view: CompetitorDeviceOverlap,
    layout: 'app',
    permission: 'user',
    title: 'Competitor Device Overlap',
  },
  {
    path: '/device-price-matrix-voice',
    view: DevicePriceMatrixVoice,
    layout: 'app',
    permission: 'user',
    title: 'Device Price Matrix Voice',
  },
  {
    path: '/device-price-matrix-data',
    view: DevicePriceMatrixData,
    layout: 'app',
    permission: 'user',
    title: 'Device Price Matrix Data',
  },
  {
    path: '/device-price-matrix-other',
    view: DevicePriceMatrixOther,
    layout: 'app',
    permission: 'user',
    title: 'Device Price Matrix Other',
  },
  {
    path: '/competitor-cheaper-devices',
    view: CompetitorCheaperDevices,
    layout: 'app',
    permission: 'user',
    title: 'Competitor Cheaper Devices',
  },

  //
  {
    path: '/device-identity-mapping',
    view: DeviceIdentityMapping,
    layout: 'app',
    permission: 'user',
    title: 'Device Identity Mapping',
  },
  {
    path: '/edit-device-identity-mapping',
    view: EditDeviceIdentityMapping,
    layout: 'app',
    permission: 'user',
    title: 'Edit Device Identity Mapping',
  },

  //

  {
    path: '/device-pricing-history',
    view: DevicePricingHistory,
    layout: 'app',
    permission: 'user',
    title: 'Device Pricing History',
  },

  //

  {
    path: '/plan-groups',
    view: PlanGroups,
    layout: 'app',
    permission: 'user',
    title: 'Plan Groups',
  },
  {
    path: '/edit-plan-groups',
    view: EditPlanGroups,
    layout: 'app',
    permission: 'user',
    title: 'Edit Plan Groups',
  },

  //

  {
    path: '/plan-identity-mapping',
    view: PlanIdentityMapping,
    layout: 'app',
    permission: 'user',
    title: 'Plan Identity Mapping',
  },
  {
    path: '/edit-plan-identity-mapping',
    view: EditPlanIdentityMapping,
    layout: 'app',
    permission: 'user',
    title: 'Edit Plan Identity Mapping',
  },

  //

  {
    path: '/plans-details',
    view: PlansDetails,
    layout: 'app',
    permission: 'user',
    title: 'Plans Details',
  },

  //

  {
    path: '/newsfeed',
    view: Newsfeed,
    layout: 'app',
    permission: 'user',
    title: 'Newsfeed',
  },

  {
    path: '/crawl',
    view: ScheduleCrawl,
    layout: 'app',
    permission: 'user',
    title: 'Schedule a Crawl',
  },
];

export default Routes;

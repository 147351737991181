/***
 *
 *   APP LAYOUT
 *   Main application layout containing the navigation
 *   and header (title, secondary nav and signed in user)
 *
 *   PROPS
 *   title: title of the view
 *
 **********/

import React, { Fragment, useContext } from 'react';
import { AuthContext, AppNav, Header, User } from 'components/lib';
import ClassNames from 'classnames';
import Style from './app.module.scss';
import '../layout.scss'; // globals

export function AppLayout(props) {
  // context & style
  const context = useContext(AuthContext);
  const css = ClassNames([Style.app, 'with-sidebar']);

  return (
    <Fragment>
      <AppNav
        type="popup"
        items={[
          {
            label: 'Newsfeed',
            icon: ['fas', 'newspaper'],
            pack: 'fontawesome',
            link: '/newsfeed',
          },
          {
            label: 'Device Price Matrix Voice',
            icon: ['fas', 'money-bill-alt'],
            pack: 'fontawesome',
            link: '/device-price-matrix-voice',
          },
          {
            label: 'Device Price Matrix Data',
            icon: ['fas', 'money-bill-alt'],
            pack: 'fontawesome',
            link: '/device-price-matrix-data',
          },
          {
            label: 'Device Price Matrix Other',
            icon: ['fas', 'money-bill-alt'],
            pack: 'fontawesome',
            link: '/device-price-matrix-other',
          },
          // {
          //   label: 'Competitor Device Overlap',
          //   icon: ['fas', 'flag'],
          //   pack: 'fontawesome',
          //   link: '/competitor-device-overlap',
          // },
          {
            label: 'Competitor Cheaper Devices',
            icon: ['fas', 'poll-h'],
            pack: 'fontawesome',
            link: '/competitor-cheaper-devices',
          },
          {
            label: 'Device Pricing History',
            icon: ['fas', 'history'],
            pack: 'fontawesome',
            link: '/device-pricing-history',
          },
          {
            label: 'Plan Groups',
            icon: ['fas', 'sim-card'],
            pack: 'fontawesome',
            link: '/plan-groups',
          },
          {
            label: 'Plans Details',
            icon: ['fas', 'info-circle'],
            pack: 'fontawesome',
            link: '/plans-details',
          },
          {
            label: 'Device Identity Mapping',
            icon: ['fas', 'mobile-alt'],
            pack: 'fontawesome',
            link: '/device-identity-mapping',
          },
          {
            label: 'Plan Identity Mapping',
            icon: ['fas', 'id-badge'],
            pack: 'fontawesome',
            link: '/plan-identity-mapping',
          },
          {
            label: 'Schedule a crawl',
            icon: ['fas', 'play-circle'],
            pack: 'fontawesome',
            link: '/crawl',
          },

          { label: 'Account', icon: 'user', link: '/account' },

          { label: 'Sign Out', icon: 'log-out', action: context.signout },
        ]}
      />

      <main className={css}>
        <Header title={props.title}>
          <User />
        </Header>

        {<props.children {...props.data} />}
      </main>
    </Fragment>
  );
}

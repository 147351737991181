import React from 'react';
import { Redirect } from 'react-router-dom';

export function Home(props) {
  const user = JSON.parse(localStorage.getItem('user'));
  const { permission = '' } = user || {};
  let redirectPath =
    permission === 'master' ? '/missioncontrol/dashboard' : '/newsfeed';

  if (!user) {
    redirectPath = '/signin';
  }

  return <Redirect to={redirectPath} />;
}

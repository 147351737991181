import React from 'react';
import { AppSmith } from 'components/appsmith/appsmith';

export function EditPlanIdentityMapping(props) {
  return (
    <AppSmith
      title="Edit Plan Identity Mapping"
      pageId="61634765ea18372f05105974"
    />
  );
}

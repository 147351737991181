/***
 *
 *   FOOTER (homepage)
 *   Static homepage footer
 *
 **********/

import React from 'react';
import { Animate, Row, Content, Link } from 'components/lib';
import Style from './footer.module.scss';

export function Footer(props) {
  return (
    <Animate type="slideup">
      <footer className={Style.footer}>
        <Row>
          <Content>
            <nav>
              {/* <Link url="/" text="Home" />
              <Link url="/signin" text="Sign in" /> */}
              {/* <Link url="/signup" text="Sign up" />
              <Link url="/terms" text="Terms" />
              <Link url="/privacy" text="Privacy" />
              <Link url="/contact" text="Contact" /> */}
            </nav>

            {/* <span>{`Copyright © Gravity ${new Date().getFullYear()}`}</span>
            <address>Gravity Inc. 42 The Milky Way. The Universe</address> */}
          </Content>
        </Row>
      </footer>
    </Animate>
  );
}

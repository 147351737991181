import React from 'react';
import Axios from 'axios';

// components
import { Router, Route, Switch } from 'react-router-dom';
import { PrivateRoute, AuthProvider } from './auth';
import { History, View } from 'components/lib';
// import { loadStripe } from '@stripe/stripe-js';
// import { Elements } from '@stripe/react-stripe-js';

// 404
import { NotFound } from 'views/error/404';

// settings
const Settings = require('settings.json');

// const StripePromise = loadStripe(
//   Settings[process.env.NODE_ENV].stripe.publishableAPIKey,
// );

const Routes = [
  // ...require('routes/setup').default,
  ...require('routes/account').default,
  ...require('routes/app').default,
  ...require('routes/auth').default,
  ...require('routes/website').default,
  ...require('routes/missioncontrol').default,
  ...require('routes/reports').default,
];

export default function App(props) {
  const user = JSON.parse(localStorage.getItem('user'));

  if (user?.token) {
    // add auth token to api header calls
    Axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.token;
  }

  // render the routes
  return (
    // <Elements stripe={StripePromise}>
    <AuthProvider>
      <Router history={History}>
        <Switch>
          {Routes.map((route) => {
            const RouteType = route.permission ? PrivateRoute : Route;

            return (
              <RouteType
                key={route.path}
                exact
                path={route.path}
                permission={route.permission}
                render={(data) => (
                  <View
                    display={route.view}
                    layout={route.layout}
                    title={route.title}
                    data={data}
                  />
                )}
              />
            );
          })}

          {/* 404 */}
          <Route
            render={() => (
              <View display={NotFound} layout="home" title="404 Not Found" />
            )}
          />
        </Switch>
      </Router>
    </AuthProvider>
    // </Elements>
  );
}

import React from 'react';
import { SeekTable } from 'components/seekTable/seekTable';
import { useAPI } from 'components/lib';

export function DevicePriceMatrixVoice(props) {
  const crawlPeriod = useAPI('/api/data/getLatestCrawlPeriod');

  return crawlPeriod.loading ? (
    <div>Loading...</div>
  ) : (
    <SeekTable
      location={props.location}
      path={props.path}
      name="Device Price Matrix Voice"
      reportId="1a30808b41a64e4ebf4eb0fccb0ad9be"
      reportParams={{ crawlPeriod: crawlPeriod.data }}
    />
  );
}

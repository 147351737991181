import React from 'react';
import { SeekTable } from 'components/seekTable/seekTable';
import { useAPI } from 'components/lib';

export function DevicePriceMatrixData(props) {
  const crawlPeriod = useAPI('/api/data/getLatestCrawlPeriod');

  return crawlPeriod.loading ? (
    <div>Loading...</div>
  ) : (
    <SeekTable
      location={props.location}
      path={props.path}
      name="Device Price Matrix Data"
      reportId="385d9c0497ae45b2a59a39cb8781a16d"
      reportParams={{ crawlPeriod: crawlPeriod.data }}
    />
  );
}

import React from 'react';
import { SeekTable } from '../../components/seekTable/seekTable';

export function Newsfeed(props) {
  return (
    <SeekTable
      location={props.location}
      path={props.path}
      name="Newsfeed"
      reportId="acaf506a221e4a9298404d7968126507"
    />
  );
}

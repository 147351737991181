import React from 'react';
import { useAPI } from '../../components/lib';

import { SeekTable } from '../../components/seekTable/seekTable';

export function CompetitorCheaperDevices(props) {
  const crawlPeriod = useAPI('/api/data/getLatestCrawlPeriod');

  return crawlPeriod.loading ? (
    <div>Loading...</div>
  ) : (
    <SeekTable
      location={props.location}
      path={props.path}
      name="Competitor Cheaper Devices"
      reportId="e2575ac789b14ab6bcc98e95eb8d7bb1"
      reportParams={{ crawlPeriod: crawlPeriod.data }}
    />
  );
}

/***
 *
 *   SOCIAL SHARING BUTTONS
 *   A sharing wiget for Facebook, Twitter, Linkedin and email
 *
 *   PROPS
 *   url: url of page to share
 *   description: text for the socal media post.
 *
 **********/

import React from 'react';
import { Icon } from 'components/lib';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import Style from './social.module.scss';

export function SocialShare(props) {
  library.add(fab);

  return (
    <div className={Style.shareButtons}>
      <a
        className={Style.facebook}
        href={`http://www.facebook.com/share.php?u=${props.url}`}
      >
        <FontAwesomeIcon icon={['fab', 'facebook']} size="lg" />
      </a>

      <a
        className={Style.twitter}
        href={`http://twitter.com/share?text=${props.description}&url=${props.url}`}
      >
        <FontAwesomeIcon icon={['fab', 'twitter']} size="lg" />
      </a>

      <a
        className={Style.linkedin}
        href={`https://www.linkedin.com/sharing/share-offsite/?url=${props.url}`}
      >
        <FontAwesomeIcon icon={['fab', 'linkedin']} size="lg" />
      </a>

      <a
        className={Style.mail}
        href={`mailto:?subject=You must see this!&body=${props.description}%0D%0A%0D%0A${props.url}`}
      >
        <Icon color="light" image="mail" className={Style.icon} />
      </a>
    </div>
  );
}

import React from 'react';

import { SeekTable } from 'components/seekTable/seekTable';
import { Button } from 'components/button/button';

export function PlanIdentityMapping(props) {
  return (
    <React.Fragment>
      <SeekTable
        location={props.location}
        path={props.path}
        name="Plan Identity Mapping"
        reportId="fa75a9d9247d41b089d7177a3f236e2b"
        bottomOffset={50}
      />
      <div style={{ position: 'absolute', bottom: 15 }}>
        <Button
          text="Edit Plan Identity Mapping"
          url="/edit-plan-identity-mapping"
        />
      </div>{' '}
    </React.Fragment>
  );
}

import React from 'react';
import { AppSmith } from 'components/appsmith/appsmith';

export function EditDeviceIdentityMapping(props) {
  return (
    <AppSmith
      title="Edit Device Identity Mapping"
      pageId="617344e7515e45415cc7f652"
    />
  );
}

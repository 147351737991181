import React from 'react';
// import { SeekTable } from 'components/seekTable/seekTable';

export function PlansDetails(props) {
  return (
    <></>
    // Temporarily removing this, as it's a pain to keep them updated.
    // <SeekTable
    //   location={props.location}
    //   path={props.path}
    //   name="Plan Details"
    //   reportId="23f89af5eb2b40079d8b54dfa656932f"
    // />
  );
}

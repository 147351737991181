import React from 'react';

import { SeekTable } from '../../components/seekTable/seekTable';

export function CompetitorDeviceOverlap(props) {
  return (
    <SeekTable
      location={props.location}
      path={props.path}
      name="Competitor Device Overlap"
      reportId="fb9e546d95fb476fbd0782f8dc985d2f"
    />
  );
}

import React from 'react';

import { SeekTable } from 'components/seekTable/seekTable';
import { Button } from 'components/button/button';

export function PlanGroups(props) {
  return (
    <React.Fragment>
      <SeekTable
        location={props.location}
        path={props.path}
        name="Plan Groups"
        reportId="35e63544f81e4403ab77dff5c506bfe0"
        bottomOffset={50}
      />

      <div style={{ position: 'absolute', bottom: 15 }}>
        <Button text="Edit Plan Groups" url="/edit-plan-groups" />
      </div>
    </React.Fragment>
  );
}

import React, { useContext } from 'react';
import { AuthContext, Animate, Row, Card, Form, History } from 'components/lib';

export function SigninOTP(props) {
  const context = useContext(AuthContext);

  const token = props?.location?.search?.substring(7);
  if (!token) History.push('/signin');

  return (
    <Animate type="pop">
      <Row
        title="Enter Verification Code"
        desc="Please enter the the verification code from your authenticator app"
      >
        <Card restrictWidth center>
          <Form
            method="post"
            url="/api/auth/otp"
            data={{
              code: {
                type: 'text',
                label: 'Verification code',
                required: true,
              },
              jwt: {
                type: 'hidden',
                value: token,
              },
            }}
            buttonText="Complete Sign In"
            callback={context.signin}
          />

          <span style={{ fontSize: '0.9em', fontStyle: 'italic' }}>
            Lost access to your authenticator app? Please enter your backup code
            above.
          </span>
        </Card>
      </Row>
    </Animate>
  );
}
